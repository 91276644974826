@import '../../../app/assets/styles/variables.scss';

.master-project-detail-card {
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.25);
    padding: 20px;
    margin: 10px;

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        h1 {
            font-size: 19px;
            color: #141b35;
            margin-top: 4px;
        }
    }

    .body {
        div.ant-typography {
            color: $text-color-light-grey;
            font-size: 14px;
            margin-bottom: 1rem;
        }

        .details {
            font-size: 14px;

            .ant-tag {
                font-size: 14px;
                padding-top: 2px;
                padding-bottom: 2px;
            }

            .organization,
            .location {
                display: inline-block;
                vertical-align: middle;
                color: $text-color-light-grey;

                img {
                    max-height: 28px;
                }
            }

            .location {
                margin-left: 10px;
            }
        }
    }
}

.master-project-detail-container {
    padding-right: 10px;
    padding-left: 10px;
    flex: 1;
}
.list-sub-project-heading {
    font-size: 1.1rem;
    font-weight: 600;
    margin-top: 10px;
    font-family: $alectify-regular;
    height: 100%;
    margin: 20px;
}

.sub-project-listing-container {
    height: 100%;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
}

.completed-container {
    background-color: #faddd4;
    padding: 5px;
    border-radius: 10px;
    font-size: 12px;
    font-weight: 500;
    color: #389e0d;
    background: #f6ffed;
    border-color: #b7eb8f;
    border: 1px solid #389e0d;
}

.alectify-regular-button {
    font-size: 14px;
    height: 32px !important;
    padding: 0 13px !important;
    border-radius: 10px;
}
