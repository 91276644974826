.ant-popover-inner {
    padding: 0px !important;
    background-color: #ffffff;
    background-clip: padding-box;
    border-radius: 8px;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12),
        0 9px 28px 8px rgba(0, 0, 0, 0.05);
    .ant-popover-title {
        min-width: 177px;
        font-weight: 600;
        padding: 12px 16px;
        border-bottom: none;

        div {
            text-align: start;
            margin-bottom: 0px;
        }
    }
    .ant-popover-inner-content {
        padding: 1px 16px !important;
    }
}

.alectify-map-marker-icon {
    &:hover {
        border-radius: 10000px;
        border: 1px solid #13a90f;
        background: #d8ffce;
        width: 25px;
    }
}
