@import '../../assets/styles/variables.scss';

.filters-sidebar {
    background-color: #fff;
    width: 100%;
    height: calc(100vh - 127px);
    padding: 20px;
    box-shadow: 7px 0 27px rgba(0, 0, 0, 0.05);

    .sidebar-heading {
        border-bottom: 1px solid #d7d7d7;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;

        h4,
        a {
            font-size: 20px;
            color: #555;

            .anticon-down {
                margin-left: 10px !important;
            }
        }
    }
}

.alectify-master-project-sidebar-container {
    .ant-drawer-body {
        padding: 20px 5px 0px 10px !important;
        overflow: hidden;
    }

    &.ant-drawer {
        .ant-input-affix-wrapper {
            border-radius: 10px;
            width: 100%;
        }
        left: 80px;
        .ant-drawer-content-wrapper {
            border-radius: 0px 20px 20px 0px;
            transform: translateX(0px) !important;
            box-shadow: 5px 1px 10px 4px #0000001c;

            .ant-drawer-content {
                border-radius: 0px 20px 20px 0px;
                border-left: 1px solid #dae5f3;
                background: #fff;
            }
        }
        .ant-drawer-mask {
            background: none;
            z-index: 0;
        }
    }
}

.project-sidebar-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    position: relative;
    .project-sidebar-header {
        height: 7rem;
        .project-create-new-link {
            display: flex;
            justify-content: flex-end;
            margin: 5px 10px 5px 10px;
            cursor: pointer;
            .ant-typography {
                color: $alectify-primary-blue;
                font-size: 0.9375rem;
                font-style: normal;
                font-weight: 600;
                line-height: 1.5rem; /* 160% */
                letter-spacing: -0.00563rem;
            }
        }
        .project-header {
            h2 {
                color: #383838;
                font-size: 1.1rem;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }
        }
    }

    .project-sidebar-content {
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow-y: auto;

        .project-sidebar-box {
            display: flex;
            border-radius: 0.375rem 0.375rem 0rem 0rem;
            border-bottom: 1px solid #e8e8e8;
            padding: 0.875rem 1.25rem;
            cursor: pointer;
            user-select: none;
            .project-sidebar-title {
                display: flex;
                align-items: center;

                span {
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    width: 12.8rem;
                    overflow: hidden;
                    color: #545454;
                    font-size: 0.875rem;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 1.5rem;
                    letter-spacing: -0.00525rem;
                    margin-left: 4px;
                }
            }
            .project-sidebar-subProject-count {
                display: flex;
                align-items: center;
                padding-left: 3px;
                color: #545454;
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 500;
                line-height: 1.5rem;
                letter-spacing: -0.00525rem;
            }

            &:hover {
                background: $alectify-light-blue-color;
                div {
                    color: $alectify-primary-blue;
                }
                span {
                    color: $alectify-primary-blue;
                }
            }
        }
    }

    .project-sidebar-footer {
        display: flex;
        padding: 0.7rem 0rem;
        border-top: 1px solid #e6e6e6;
        position: fixed;
        bottom: 0;
        width: 100%;
        left: 0;
        background-color: #fff;

        .project-sidebar-active {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50%;
            text-align: center;
            border-right: 1px solid #e6e6e6;
        }
        .project-sidebar-completed {
            width: 50%;
            text-align: center;
        }
        span {
            color: #545454;
            font-size: 0.8125rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1.5rem;
            letter-spacing: -0.00488rem;
            margin-left: 5px;
        }
    }
}

.active-project {
    background: $alectify-light-blue-color;
    .project-sidebar-title {
        span {
            color: $alectify-primary-blue !important;
        }
    }

    .project-sidebar-subProject-count {
        color: $alectify-primary-blue !important;
    }

    .bidding-sidebar-title {
        span {
            color: $alectify-primary-blue !important;
        }
    }
}

.bidding-sidebar-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
    margin-bottom: 29px;

    .bidding-sidebar-box {
        display: flex;
        border-radius: 0.375rem 0.375rem 0rem 0rem;
        border-bottom: 1px solid #e8e8e8;
        padding: 0.875rem 0.6rem;
        cursor: pointer;
        user-select: none;
        .bidding-sidebar-title {
            display: flex;
            align-items: center;

            span {
                width: 220px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                color: #545454;
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 500;
                line-height: 1.5rem;
                letter-spacing: -0.00525rem;
            }
        }

        &:hover {
            background: $alectify-light-blue-color;
            div {
                color: $alectify-primary-blue;
            }
            span {
                color: $alectify-primary-blue;
            }
        }
    }
}
