.alectify-input {
    input {
        height: 45px !important;
        color: #232323;
        font-size: 14px !important;
        width: 100%;
    }

    .ant-input[type='color'] {
        height: 45px;
    }
    label {
        color: #0c0b0b;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: 0.16px;
    }

    .ant-input-affix-wrapper:focus {
        border-color: #0954f1;
    }
}

.ant-form-item-label {
    label {
        &:has(.form-label-extra) {
            width: 100%;
        }
    }
}
