@import './app/assets/styles/variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap');

@import '/src/app/assets/styles/components/breakpoints.scss';

* {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
    height: fit-content;
}

.pkg-detail {
    min-width: 500px;
}

.modal-content {
    max-height: calc(100vh - 220px);
    overflow-y: auto;
    overflow-x: hidden;
}

.deadline-message-text {
    font-weight: 500;
    color: #9c9998;
    display: flex;
    align-items: center;
    justify-content: center;
}
.update-equipment-modal {
    .ant-modal-title {
        text-align: center;
    }
}

.custom-leaflet-container {
    .leaflet-popup-content-wrapper {
        width: 537px;
    }
}

.pkg-detail-breadcrumb {
    max-width: 135px;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.container {
    padding-left: 60px;
    padding-right: 60px;
}

.tags-header {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    padding-right: 20px;

    .buttons {
        display: flex;
    }
}

.ant-input-number {
    width: 100%;
}

.page-content-container {
    width: 100%;
    min-height: 400px;
    display: flex;
    flex: 1;
    height: 100%;
    flex-direction: column;
    background-color: #fff;
    border-radius: 0px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
        0 3px 1px -2px rgba(0, 0, 0, 0.2);

    .project-header {
        display: flex;
        justify-content: space-between;
        border-bottom: 2px solid #f2f2f2;
        align-items: center;

        .ant-tag {
            font-size: 14px;
            padding: 5px 10px;
            margin-bottom: 0;
            margin-right: 24px;
            border-radius: 10px;
        }
    }

    .steps-content {
        padding: 0 24px 24px;
    }

    &.equpmt-chart .apx-legend-position-bottom {
        inset: auto 0px -5px 5px !important;
    }
}

.word-cloud-container {
    height: 330px;
}

.word-cloud-img {
    width: 100%;
    height: 280px;
    object-fit: contain;
}

.text-center {
    text-align: center !important;
}

.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}

.eqp-arrow-adjust {
    .ant-select-arrow {
        margin-top: -16px;
    }
}

.no-button {
    padding: 0;
    border: 0;
    background: none !important;
}

.mb-15 {
    margin-bottom: 15px;
}

.mb-30 {
    margin-bottom: 30px;
}

.normal-label {
    display: block;
    margin-bottom: 6px;
}

.full-width {
    width: 100%;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.mt-0 {
    margin-top: 0 !important;
}

.button-red {
    background-color: #eb5a46 !important;
    color: #fff !important;
}

.button-yellow {
    background-color: #fc9f19 !important;
    color: #fff !important;
}

.button-blue {
    background-color: #1890ff !important;
    color: #fff !important;
}

.button-green {
    background-color: #61bd4f !important;
    color: #fff !important;
}

.page-heading {
    color: $primary-color;
}

.font-weight-600 {
    font-weight: 600;
}

.cursor-pointer {
    cursor: pointer;
}

.w-100 {
    width: 100%;
}

.sline-item {
    border-radius: 5px;
    border: 1px solid #d7d7d7 !important;
    box-shadow: 1px 1px 3px #cfcfcf;

    & + .sline-item {
        margin-top: 15px;
    }

    .ant-list-item-meta {
        margin-bottom: 10px;
    }

    .ant-list-item-meta-title > a {
        color: $text-grey;
        transition: all 0.3s;
        font-size: 20px;
    }

    p {
        color: #777;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &:hover {
        background-color: #f9f9f9;
    }

    &.active {
        background-color: #f9f9f9;

        // .ant-list-item-meta-title > a {
        //     color: #fff
        // }

        // p {
        //     color: #f9f9f9;
        // }

        // .ant-list-item-action {
        //     li {
        //         color: #fff;
        //     }
        // }
    }
}

.ant-breadcrumb {
    .ant-breadcrumb-link {
        font-size: 13px;
        text-transform: capitalize;
        color: #333;
    }
}

.project-detail-tabs {
    overflow: visible;
    background-color: transparent;

    &.upload-doc-open {
        .files-tab {
            display: block !important;
        }
    }

    .ant-tabs-nav {
        border-bottom: 1px solid #d7d7d7;
    }

    .ant-tabs-nav::before {
        display: none;
    }

    .ant-tabs-nav-wrap {
        width: 100%;
        display: inline-block !important;
        // background: #f5f5f5;
        margin: 10px 0 0;

        .ant-tabs-tab {
            justify-content: center;
            font-size: 15px;
            padding: 14px 24px;
            // color: #333333;
            color: #565252;

            // font-weight: 500;
            // border-radius: 37px;
            // background: #f9f9f9;
            // border: 1px solid #d7d7d7;
            span {
                justify-content: center;
                align-items: center;
            }

            .ant-badge {
                color: #333333;
            }

            &.ant-tabs-tab-active {
                .ant-badge {
                    color: $primary-color;
                    font-weight: 500;
                }
            }

            & + .ant-tabs-tab {
                margin: 0;
                // margin-left: 10px;
            }
        }
    }

    &.white-bg-tabs {
        & > .ant-tabs-nav {
            .ant-tabs-tab {
                background-color: transparent;
            }
        }
    }

    &.nested-tabs {
        .white-bg-tabs {
            .ant-tabs-tab {
                background: transparent;
            }
        }
    }

    .ant-tabs-tab-active {
        // background-color: $primary-color !important;
        border-bottom: 2px solid $primary-color;
        // border-color: $primary-color;
    }

    .ant-tabs-ink-bar {
        background: none;
    }
}

.d-block {
    display: block;
}

.project-list h4 {
    margin-bottom: 0 !important;
}

.section-heading {
    font-size: 24px;
    font-weight: normal;
    color: $text-grey;
    align-items: center;
    padding-bottom: 12px;
}

.d-flex {
    display: flex;
}

.justify-space-between {
    justify-content: space-between;
}

.justify-content-center {
    justify-content: center;
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.mb-10 {
    margin-bottom: 10px !important;
}

.m-auto {
    margin: auto;
}

.height-100vh {
    height: 100vh;
}

.ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
}

.ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    display: inline-block;
    margin-left: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
}

.width-100 {
    width: 100%;
}

.btn-lg {
    font-size: 18px;
    height: auto;
    padding: 10px 50px;
    border-radius: 37px;
    font-weight: 600;
    -webkit-box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.12);
}

.btn-primary-lg {
    color: #f1f1f1;
    background-color: $primary-color;
}

.btn-default {
    background-color: #f9f9f9;
    color: $text-grey;

    &:hover {
        background-color: #f1f1f1;
        color: $text-grey !important;
        border-color: #d7d7d7 !important;
    }
}

.m-20 {
    margin: 20px;
}

.mt-20 {
    margin-top: 20px !important;
}

.mt-8 {
    margin-top: 8px !important;
}

.mt-10 {
    margin-top: 10px !important;
}

.mt-15 {
    margin-top: 15px !important;
}

.mt-5 {
    margin-top: 5px !important;
}

.mt-30 {
    margin-top: 30px;
}

.mt-50 {
    margin-top: 50px;
}

.btn {
    padding: 6px 16px;
    cursor: pointer;
    border-width: 1px;
    border-radius: 2px;
    font-size: 14px;
    border: 1px solid #d7d7d7;
    border-radius: 30px;
    margin-left: 2px;

    &.btn-outline-primary {
        color: $primary-color;
        border-color: $primary-color;
    }

    &:hover {
        background-color: $primary-color;
        color: #fff;
    }
}

#components-layout-demo-side .logo {
    height: 32px;
    margin: 16px;
    background: rgba(255, 255, 255, 0.3);
}

.logo {
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        object-fit: contain;
        max-height: 51px;
    }
}

.ant-btn-background-ghost {
    &.ant-btn-primary {
        color: $primary-color;
        border-color: $primary-color;

        &:hover {
            background-color: $primary-color;
            color: #f9f9f9;
        }
    }
}

.ant-btn-primary {
    border-color: $primary-color;
    background-color: $primary-color;

    &:hover,
    &:focus {
        background-color: $dark-primary-color;
        border-color: $dark-primary-color;
        color: #fff !important;
    }
}

.ant-btn:hover,
.ant-btn:focus {
    border-color: $primary-color;
    color: $primary-color;
}

.meta-h4 {
    h4 {
        margin-bottom: 0 !important;
    }
}

.ant-layout {
    background: #fbfbfb;
}

.text-right {
    text-align: right;
}

.ant-layout-sider {
    background-color: $dark-blue-color;
}

.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
    background-color: $dark-blue-color;
}

.ant-menu-sub.ant-menu-inline > .ant-menu-item,
.ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    height: 222px;
    line-height: 10px;
    margin-top: 10px;
    list-style-position: inside;
    list-style-type: disc;
}

.ant-submenu-open {
    height: 400px;
    width: 400px;
}

.ant-menu-item-only-child {
    height: 270px !important;
}

.ant-menu-item {
    padding: 0;
}

.save-btn {
    font-size: 16px;
    padding: 9px 43px;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: $primary-color;
}

.color-primary {
    color: $primary-color;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
.ant-input-affix-wrapper:hover,
.ant-input:focus,
.ant-input-focused,
.ant-input:hover {
    border-color: $primary-color;
}

.ant-switch-checked {
    background-color: $green-color !important;
}

.ant-switch {
    background-color: #ccc;
}

.placeholder-container {
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ant-layout-sider {
    .ant-menu-title-content {
        font-size: 15px;
    }

    .ant-menu-item {
        .ant-menu-item-icon {
            font-size: 21px;
        }
    }
}

.approval-design-td {
    max-width: 140px !important;
    text-align: center;
}

.taglist-popup > .ant-modal-content > .ant-modal-header > .ant-modal-title {
    text-transform: uppercase;
}

.taglist-table {
    .ant-table-thead > tr > th {
        color: #7f7e7e;
        text-transform: uppercase;
        font-size: 13px;
    }

    .ant-table-tbody > tr > td {
        padding: 10px 7px !important;
        color: $text-grey;
        transition: none;
    }

    .ant-table-tbody .ant-checkbox-inner {
        border-color: #1890ff;
    }

    .ant-table-tbody > tr.ant-table-row:hover > td {
        box-shadow: 2px 1px 2px #e3e3e3;
    }
}

.popup-taglist-table {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);

    .ant-table-thead > tr > th {
        text-transform: uppercase;
        font-size: 13px;
        background-color: #16aaff;
        color: #fff !important;
        font-weight: bold;
    }

    .ant-table-tbody > tr > td {
        padding: 10px 7px !important;
        color: $text-grey;
        transition: none;
    }

    .ant-table-tbody > tr.ant-table-row:hover > td {
        box-shadow: 2px 1px 2px #e3e3e3;
        font-weight: 400;
        color: #16aaff;
    }

    .ant-table-tbody > tr.ant-table-row:nth-of-type(even) > td {
        background: #f3f3f3;
    }

    .ant-table-tbody > tr.ant-table-row:last-of-type > td {
        border-bottom: 2px solid #16aaff;
    }
}

.ant-table-container table > thead > tr:first-child th:last-child {
    color: #7f7e7e;
}

.ant-table-thead > tr > th {
    color: #7f7e7e !important;
}

.d-inline-block {
    display: inline-block;
}

.alectify-notification {
    cursor: pointer;
    width: 55px;
    text-align: center;
    position: relative;
    justify-content: center;
    display: flex;
    height: 100%;
    align-items: center;

    .anticon {
        font-size: 25px;
        color: #969696;
    }

    &.active {
        background-color: #f9f9f9;
    }

    .search-tags {
        position: absolute;
        right: 0;
        bottom: -69px;
        background: #fff;
        padding: 0px 10px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.18);
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.18);
        padding-bottom: 5px;

        .ant-input {
            width: 250px;
            background-color: #fff;
            border-radius: 30px;
            padding-left: 43px;
            height: 40px;
            box-shadow: 1px 1px 1px #e2e2e2;
            font-size: 15px;
            color: $text-grey;
            background: url('app/assets/images/search-icon.png') no-repeat center left 15px;
            background-size: 15px;

            &:hover,
            &:focus {
                border-color: #48c074;
                box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.1), 0 0 0 3px #e3f7f8;
            }
        }
    }

    &:hover {
        background-color: #f9f9f9;
    }
}

.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon {
    font-size: 21px;
}

.ant-pagination {
    margin: 10px 20px !important;
}

.ant-layout-footer {
    background-color: #fff;
    padding: 20px 50px;
    z-index: 9;
    color: $text-grey;
    text-align: center;
    font-size: 12px;
}

.ant-pagination-item a:hover {
    color: $primary-color;
}

.ant-pagination-item-active a {
    color: #fff !important;
    background: $primary-color;
    border-color: $primary-color;
}

.sidebar-listing {
    padding: 0 20px;
    margin-left: -20px;
    margin-right: -20px;
    height: calc(100% - 142px);

    .ant-collapse-content-box {
        padding: 0;
    }

    ul {
        li {
            display: block;

            & + li {
                margin-top: 7px;
            }

            .list-item-container {
                padding: 10px;
                position: relative;
                border-radius: 0px;
                cursor: pointer;
                justify-content: space-between;
                border: 1px solid #d7d7d7;
                border-radius: 6px;

                .list-detail {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }

                .sidebar-list-icon {
                    font-size: 23px;
                    margin-right: 10px;
                    color: #777;
                    vertical-align: middle;
                }

                .edit-icon {
                    background: none;
                    outline: none;
                    border: 0;
                    font-size: 18px;
                    line-height: 0;
                    color: #777;
                    width: 22px;
                    height: 22px;
                    border-radius: 50%;
                    text-align: center;
                    cursor: pointer;

                    &:hover {
                        color: #555;
                    }
                }

                .sidebar-list-text {
                    font-size: 0.9rem;
                    color: #333;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font-weight: 400;
                }

                &:hover {
                    background-color: #f1f1f1;
                }

                &.active {
                    background: #dbf2ff;
                    border-left: 5px solid $primary-color;
                }
            }
        }
    }
}

.ant-layout-content {
    margin: 16px;

    &.content-width-sidebar {
        margin-left: 0;
        margin-top: 0;
        margin-bottom: 0;
    }
}

.ant-steps-item-process {
    .ant-steps-item-icon {
        border-color: $primary-color;
    }

    & > .ant-steps-item-container > .ant-steps-item-icon {
        background-color: $primary-color;
    }
}

.ant-steps-item-finish {
    .ant-steps-item-icon {
        border-color: $primary-color;

        & > .ant-steps-icon {
            color: $primary-color;
        }
    }
}

.ant-steps-item-title {
    font-size: 19px;
}

.fixed-right {
    max-height: calc(100vh - 127px);
    overflow-y: auto;
    overflow-x: hidden;
}

.have-nested-containers {
    .page-content-container {
        box-shadow: none;
        padding: 0;
    }
}

.card-heading {
    margin: 0;
    color: #333333;
    font-size: 16px;
    text-transform: uppercase;
    border-bottom: 1px solid #d7d7d7;
    padding: 10px;
    margin-bottom: 20px;
    font-weight: 500;
}

.ant-empty-description {
    color: #777;
    font-size: 21px;
    text-transform: capitalize;
}

.custom-scroll {
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #eee;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #ddd;
    }
}

.custom-scroll-dark {
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: transparent;
        background-color: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #ddd;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #ddd;
    }
}

.fixed-layout {
    max-height: calc(100vh - 143px);
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: 0;

    &::-webkit-scrollbar {
        display: none;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        display: none;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        display: none;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        display: none;
    }
}

.hide-stroke {
    tspan {
        stroke: none;
    }
}

.btn-tab {
    font-size: 14px;
    padding: 6px 14px;
    border-radius: 4px;
    height: auto;
    font-weight: normal;
    font-weight: 600;
    -webkit-box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.12);
}

.white-bg-search {
    .search-box .ant-input {
        background-color: #fff;
    }
}

.status-flow {
    display: flex;
    // justify-content: center;
    margin-top: 30px;
    margin-bottom: 30px;

    .flow-status-container {
        position: relative;
        width: 200px;
        overflow: hidden;

        .mark-status {
            width: 100px;
            height: 80px;
            background-color: #eee;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            color: $text-grey;
            border-radius: 4px;

            label {
                font-size: 13px;
            }

            p {
                font-size: 13px;
                margin: 0;
            }

            &.yellow {
                background-color: #f6d22b;
                color: #333;
            }

            &.green {
                background-color: #4dcd73;
                color: #fff;
            }
        }

        &::after {
            position: absolute;
            left: 100px;
            display: block;
            width: 100%;
            height: 5px;
            background: #f0f0f0;
            content: '';
            top: 0;
            bottom: 0;
            margin: auto;
        }

        &:last-child {
            &::after {
                display: none;
            }
        }
    }
}

.alectify-collapse {
    border: 0;

    overflow: hidden;

    .ant-collapse-header {
        background-color: #fff;
        color: $text-grey !important;
    }

    .ant-collapse-item {
        -webkit-box-shadow: -7px 2px 15px -8px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: -7px 2px 15px -8px rgba(0, 0, 0, 0.2);
        box-shadow: -7px 2px 15px -8px rgba(0, 0, 0, 0.2);
    }

    .ant-collapse-item-active {
        .ant-collapse-header {
            background-color: #fafafa;
        }
    }
}

.packages-collapse {
    .ant-collapse-content > .ant-collapse-content-box {
        padding: 0;
    }

    .ant-collapse-item-active {
        .ant-collapse-header {
            background-color: #fff;
        }
    }
}

.normal-btn {
    font-size: 14px;
    padding: 9px 43px;
    font-weight: normal;
    box-shadow: none;
}

.small-upload-cards {
    .ant-upload-list-picture-card-container,
    .ant-upload-select-picture-card {
        height: 80px;
    }

    .ant-upload-list-picture-card .ant-upload-list-item-file + .ant-upload-list-item-name {
        font-size: 12px;
        bottom: 0;
    }
}

.breakdown-collapse {
    margin-top: 20px;

    .ant-collapse-header {
        padding: 0 !important;
    }
}

.package-tag-container {
    width: 80%;
    margin: auto;
}

.mb-25 {
    margin-bottom: 25px;
}

.no-projects-wrapper {
    height: calc(100% - 200px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.subscription-message {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .ant-empty-description {
        font-size: 18px;
        text-transform: capitalize;
    }
}

.no-projects-empty {
    .ant-empty-image svg {
        width: 250px !important;
        height: 250px !important;
    }
}

.m-0 {
    margin: 0 !important;
}

.table-search-btn {
    .ant-table-filter-trigger {
        color: #16aaff;
    }
}

.delete-equipment-message {
    min-width: 90px;
}

.delete-eqmt-content {
    margin: 0 0 40px 0;

    h4 {
        margin: 0 0 8px 0;
    }

    p {
        margin: 0 0 4px 0;

        span {
            font-weight: 600;
            margin: 0 0 0 8px;
        }
    }
}

.project-steps {
    display: flex;
    background-color: #fcfcfc;

    .project-step {
        position: relative;
        padding: 20px 20px;
        font-size: 17px;
        color: #d4d4d4;

        & + .project-step {
            margin-left: 15px;
        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 100%;
            width: 1em;
            height: 100%;
            background: transparent url('./app/assets/images/wizard-arrow.png') no-repeat center
                center;
            background-size: 100% 100%;
        }

        &.first-step {
            background-color: #50aaf4;
            margin-right: -15px;
            color: #fff;

            p {
                margin: 0;
            }

            span {
                font-weight: 700;
            }
        }

        &:first-child::after {
            display: none;
        }

        &.active {
            color: $primary-color;
            font-weight: 600;
        }
    }
}

.step-heading {
    color: $text-grey;
    margin-bottom: 30px;
    justify-content: space-between;
    display: inline-flex;
    width: 100%;
}

.step-heading-inline {
    color: $text-grey;
    margin-bottom: 30px;
    justify-content: space-between;
    display: inline-flex;

    div {
        margin: 0 0 0 8px;
    }
}

.document-collapse {
    border: 1px solid #d9d9d9;
    box-shadow: none !important;
}

.ant-progress-text {
    color: $text-grey;
}

.ant-progress-status-success .ant-progress-bg {
    background-color: #e37172 !important;
}

.text-grey {
    color: $text-grey;
}

.align-items-center {
    align-items: center;
}

.dashboard-card {
    min-height: 160px;
    margin-bottom: 25px;
    padding: 24px;

    .custom-card-heading {
        padding-bottom: 0;
        margin-bottom: 0;
        text-align: center;
        border-bottom: 0;
    }

    .dashboard-card-text {
        font-size: 40px;
        color: $text-grey;
        text-align: center;
        margin: 0;

        span {
            font-size: 30px;
        }
    }

    .dashboard-ratings {
        margin: auto;
        width: 84%;
        margin-top: 15px;

        .rating-block {
            display: block;

            .ant-rate-text {
                color: $text-grey;
            }

            & + .rating-block {
                margin-top: 7px;
            }
        }
    }

    .dashboard-list {
        width: 84%;
        margin: auto;
        margin-top: 15px;

        li {
            font-size: 15px;
            display: flex;
            justify-content: space-between;
            color: $text-grey;

            .left-side {
                display: flex;
                align-items: center;

                span {
                    font-size: 22px;
                    color: yellow;
                    position: relative;
                    z-index: 999;
                    margin-right: 10px;
                    width: 30px;
                    height: 30px;
                    display: inline-block;

                    &.happy {
                        background: url('./app/assets/images/emojis.jpg') no-repeat left -36px top -70px;
                        background-size: 139px;
                    }

                    &.normal {
                        background: url('./app/assets/images/emojis.jpg') no-repeat left -5px top -10px;
                        background-size: 139px;
                    }

                    &.sad {
                        background: url('./app/assets/images/emojis.jpg') no-repeat left -66px top -40px;
                        background-size: 139px;
                    }
                }
            }

            p {
                margin: 0;
                color: $text-grey;
                font-size: 16px;
            }

            & + li {
                margin-top: 10px;
            }
        }
    }
}

.dashbaord-user-sentiments {
    ul {
        li {
            display: block !important;
            text-align: center;

            .sentiment-cover {
                text-align: center;
                display: inline-block;
            }

            .left-side {
                display: block !important;

                span {
                    margin-right: 0px !important;
                    width: 110px !important;
                    height: 110px !important;
                    background-size: 533px;

                    &.happy {
                        background: url('./app/assets/images/emojis.jpg') no-repeat left -188px top -324px !important;
                        background-size: 629px !important;
                    }

                    &.normal {
                        background: url('./app/assets/images/emojis.jpg') no-repeat left -47px top -59px !important;
                        background-size: 629px !important;
                    }

                    &.sad {
                        background: url('./app/assets/images/emojis.jpg') no-repeat left -56px top -455px !important;
                        background-size: 629px !important;
                    }
                }
            }

            p {
                font-size: 21px !important;
                font-weight: 600;
            }
        }
    }
}

.ant-collapse {
    .ant-collapse-item-disabled {
        & > .ant-collapse-header {
            color: rgba(0, 0, 0, 0.5) !important;
            background: #f9f9f9;
        }
    }
}

.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover {
    color: rgba(0, 0, 0, 0.25) !important;
}

.text-size-s {
    font-size: 0.9rem;
}

.chart-wrapper {
    width: 100%;
    display: inline-block;
    overflow: hidden;
}

.main-dashboard {
    .card-heading {
        font-weight: 600;
        color: #000;
    }

    .custom-card-heading {
        color: #555 !important;
    }
}

.dashboard-down-cards {
    .dashboard-list {
        margin-top: 37px;

        li {
            font-size: 20px;
            font-weight: 600;

            p {
                font-size: 20px;
                font-weight: normal;
            }
        }
    }
}

.colorful-card {
    border-radius: 5px;

    .dashboard-card-text {
        font-size: 49px;
        color: #555;
        line-height: 60px;
    }

    .custom-card-heading {
        font-size: 20px !important;
        font-weight: 500;
        color: #555;
        text-transform: capitalize;
    }

    &.bg-pink {
        background-color: #ce7bb0;
    }

    &.bg-blue {
        background-color: #1572a1;
    }

    &.bg-orange {
        background-color: #faad14;
    }

    &.bg-brown {
        background-color: #bb6464;
    }

    &.bg-light-blue {
        background-color: #17a2b8;
    }

    &.bg-lightred {
        background-color: #ffccc7;
    }

    &.bg-light-purple {
        background-color: #acb1d6;
    }

    &.bg-light-grey {
        background-color: #f0f0f0;
    }

    &.bg-light-pinkish {
        background-color: #ffd0d0;
    }

    &.bg-light-greenish {
        background-color: #c1ece4;
    }

    &.bg-light-yellowish {
        background-color: #f5e9cf;
    }
}

.pac-container:empty {
    box-shadow: none !important;
    display: none !important;
}

.align-center {
    align-items: center;
}

.mr-14 {
    margin-right: 14px;
}

.mr-25 {
    margin-right: 25px;
}

.position-relative {
    position: relative;
}

.sidebar-toggler {
    position: absolute;
    right: 0;
    top: 0;
    background: #fff;
    padding: 4px 7px 7px 7px;
    cursor: pointer;
    z-index: 99;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    &:hover {
        background-color: #16aaff;
        color: #fff;
    }
    &.open {
        right: unset;
        left: -20px;
        top: 0px;
        padding: 4px 7px 7px 7px;
    }
}

.ml-5 {
    margin-left: 5px !important;
}

.ml-10 {
    margin-left: 10px !important;
}

.ml-15 {
    margin-left: 15px !important;
}

.ml-20 {
    margin-left: 20px !important;
}

.ml-40 {
    margin-left: 40px !important;
}
.pR-10 {
    padding-right: 10px;
}

.avatar-group-plus-icon {
    .ant-avatar-group .ant-avatar {
        height: 30px !important;
        width: 30px !important;
    }

    .ant-avatar-string {
        top: -9%;
    }
}
.package-team-members {
    display: flex;
    margin: 0 0 0 10px;

    .circle-icon {
        cursor: pointer;
    }
}

.slider {
    position: relative;
    display: flex;
    height: 100vh;

    .right-arrow {
        position: absolute;
        top: 1550px;
        right: 27%;
        font-size: 384px;
        color: black;
        z-index: 10;
        cursor: pointer;
        user-select: none;
    }

    .left-arrow {
        position: absolute;
        top: 1040px;
        left: -3.2%;
        font-size: 384px;
        color: black;
        z-index: 10;
        cursor: pointer;
        user-select: none;
    }
}

.project-search-wrapper {
    display: flex;
    align-items: center;
    padding: 0.1px 0 0.5px 0;

    .ant-select-selector {
        border-top-right-radius: 1px !important;
        border-bottom-right-radius: 1px !important;
        height: 40px;
    }

    .anticon-search {
        padding: 16px;
        color: #fff;
    }
}

.tagName-master-td {
    max-width: 140px !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.clickable-link {
    border-bottom: 1px solid #000;
    cursor: pointer;
}

.table-row-name-last-desc {
    max-width: 150px !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.description-td {
    max-width: 100px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.description-td-edit {
    max-width: 140px !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.gant-hover-container {
    padding: 10px 15px;
    width: 200px;

    .label-container {
        width: 100%;
        display: block;

        span {
            float: right;
        }
    }
}

.switch-body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .map-toggle-btn {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px 7px;
        cursor: pointer;
        font-size: 0.8rem;
        color: #777;
        background-color: #f1f4f6;
        transition: all 0.2s ease-in;
        border: 1px solid #d7d7d7;
    }

    .active-toggle {
        background: #16aaff;
        border-color: #16aaff;
        color: #fff;
    }
}

.breadcrumb-with-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.main-upload-btn {
    height: 35px;
    padding: 4px 20px;
    color: #555;
    display: flex;
    align-items: center;

    .anticon {
        font-size: 17px;
    }

    &:hover {
        background-color: $primary-color;
        color: #fff;
    }
}

.grey-row {
    background-color: #f9f9f9;
}

.accordian-section-title {
    padding: 10px;
    font-size: 14px;
    // font-weight: bold;
    text-transform: uppercase;
    position: fixed;
    background-color: white;
    z-index: 99;
}

// Chat display
.chat-box {
    margin: 0;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;

    .chat-header {
        flex: 1;
        padding: 0px;
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #000;
        font-weight: 500;
        padding-left: 20px;
        max-height: 48px;
        background-color: #e3e3e3;
        justify-content: space-between;
    }

    .chat-content {
        border-radius: 4px;
        flex: 8;
        background-color: #f5f5f5;
        display: flex;
        flex-direction: column;
        overflow-x: scroll;
        max-height: 60vh;

        .chat-message-box {
            display: flex;

            &.justify-end {
                justify-content: flex-end;
            }
        }

        .chat-message {
            padding: 10px;
            background-color: white;
            margin: 10px;
            border-radius: 4px;
            display: flex;
            flex-direction: row;
            width: 45%;
            align-items: center;
            justify-content: flex-start;

            .content {
                margin-left: 10px;
                align-items: center;
                display: flex;

                &.text-message {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    width: calc(100% - 32px);
                }

                &.file-message {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                }

                a {
                    cursor: pointer;
                    display: flex;
                }
            }

            .text {
                font-weight: normal;
            }

            .time {
                font-size: 10px;
                letter-spacing: 0.2px;
                color: #999393;
            }
        }

        .loader {
            width: 100%;
            display: flex;
            padding: 10px 0;
            align-items: center;
            justify-content: center;
            position: relative;
            bottom: 0;
            max-height: 40px;
            margin-bottom: 10px;
        }

        .spinner {
            width: 100%;
            display: flex;
            padding: 10px 0;
            align-items: center;
            justify-content: center;
            position: relative;
            bottom: 0;
            max-height: 40px;
        }
    }

    .message-box {
        width: 100%;
        padding: 20px;
        flex: 1;
        background-color: #e3e3e3;
        display: flex;
        flex-direction: Column;
        justify-content: center;

        .input-box {
            display: flex;
            flex-direction: row;

            .user-input {
                display: flex;
                flex: 1;
            }

            textarea {
                border: 0;
                border-radius: 4px;
                padding: 10px;
                font-size: 14px;
            }

            button {
                width: 100%;
                height: 100%;
            }
        }

        .extra-btns {
            margin-top: 10px;
            display: flex;
            flex-direction: row;

            button {
                margin-right: 10px;
            }

            .ant-tag {
                font-size: 16px;
                padding: 5px 20px;
            }
        }
    }
}

.equipment-detail-modal {
    .ant-card-head {
        background: #f1f1f1;

        .ant-card-head-title {
            font-size: 15px;
            padding: 11px 0;
        }
    }
}

.dashboard-lg-card {
    box-shadow: none;
    min-height: 160px;
    margin-bottom: 25px;
    padding: 24px;
    background-color: #fff;

    .heading {
        font-size: 1.4em;
        font-weight: 600;
        color: #555;
        margin: 0;
        margin-bottom: 25px;
        display: flex;
        justify-content: space-between;
    }
}

.sentiments {
    background: #f7f7f7;
    padding: 40px;
    border-radius: 20px;

    .sentiment-wrapper {
        cursor: pointer;
        text-align: center;
        background: #a4e1a4;
        padding: 30px 20px;
        border-radius: 20px;

        h1 {
            font-weight: bold;
            font-size: 1.7em;
            margin: 0;
            color: #555;
        }

        h2 {
            margin: 0;
            color: #555;
        }

        &.happy {
            background-color: #d3f0d3;

            .sentiment {
                background: url('./app/assets/images/emojis.png') no-repeat left -188px top -324px !important;
                background-size: 629px !important;
            }
        }

        &.sad {
            background-color: #f7dbdb;

            .sentiment {
                background: url('./app/assets/images/emojis.png') no-repeat right -194px top -192px !important;
                background-size: 629px !important;
            }
        }

        &.neutral {
            background-color: #f6fae6;

            .sentiment {
                background: url('./app/assets/images/emojis.png') no-repeat left -47px top -59px !important;
                background-size: 629px !important;
            }
        }

        &.mixed {
            background-color: #ebe4f2;

            .sentiment {
                background: url('./app/assets/images/emojis.png') no-repeat right -47px top -188px !important;
                background-size: 629px !important;
            }
        }

        .sentiment-progress {
            margin-bottom: -20px;
            margin-top: 20px;
        }
    }

    .sentiment {
        margin-right: 0px !important;
        width: 110px !important;
        height: 110px !important;
        background-size: 533px;
        display: inline-block;
    }
}

@media screen and (max-width: 1300px) {
    .project-detail-tabs {
        .ant-tabs-nav-wrap {
            .ant-tabs-tab {
                padding: 14px 32px;
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .project-detail-tabs {
        .ant-tabs-nav-wrap {
            .ant-tabs-tab {
                padding: 14px 21px;
            }
        }
    }
}

.full-width-modal {
    padding-bottom: 0;

    &.ant-modal {
        top: 0;
        width: 100% !important;
        max-width: 100%;
    }

    .ant-modal-body {
        height: calc(100vh - 55px);
    }
}

.binder-generation-modal {
    .ant-modal-close-x {
        font-size: 22px;
    }
    .ant-modal-body {
        height: calc(100vh);
        background-color: #deecff;
    }
}

.label-with-actions {
    .ant-form-item-label {
        label {
            width: 100%;
        }

        .label-actions {
            position: absolute;
            right: 0;
        }
    }
}

.counter {
    padding: 0px 8px;
    border-radius: 4px;
    color: white;

    &.activity {
        background-color: #faad14;
    }

    &.inbox {
        background-color: #ff6f71;
    }
}

.project-listing-collapse {
    background: none;
    border: 0;

    .ant-collapse-content-box {
        padding: 10px;
    }

    .ant-collapse-item {
        border: 1px solid #ebebeb;
        background: #fff;
        margin-bottom: 6px;
        border-radius: 6px;
        box-shadow: 3px 3px 8px #f2f2f2;
    }
}

.ant-tooltip-inner {
    font-size: 12px;
}

.exta-detail {
    font-size: 10px;
}

.sub-project-details {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .ant-tag {
        padding: 0 7px;
        font-size: 10px;
        line-height: 16px;
    }
}

.master-project-details {
    position: absolute;
    top: -4px;

    .counter {
        padding: 0;
        width: 10px;
        height: 5px;
        display: inline-block;
    }
}

.master-active {
    .ant-collapse-header {
        background-color: $primary-color;

        .ant-collapse-header-text,
        .ant-space-item,
        .ant-badge,
        .ant-collapse-extra {
            color: #fff !important;
            font-weight: bold;
        }

        .ant-collapse-arrow {
            color: #fff !important;
        }
    }
}

.ant-empty {
    &.small-text {
        .ant-empty-description {
            font-size: 15px;
        }
    }
}

.po-summary-calculation {
    height: 100%;

    h1 {
        display: flex;
        justify-content: space-between;

        font-size: 18px;
        border-bottom: 1px solid #d7d7d7;
        padding-bottom: 12px;
        color: #555;

        & + h1 {
            margin-top: 17px;
        }
    }
}

.po-information {
    .ant-col {
        margin-bottom: 30px;
    }

    label {
        display: block;
        margin-bottom: 4px;
        font-weight: 600;
        font-size: 15px;
    }

    span {
        font-size: 15px;

        &.ant-progress-text {
            font-size: 14px;
        }
    }
}

.white-bg {
    background-color: #fff;
}

.po-summary-heading {
    border-bottom: 1px solid #ededed;
    padding-bottom: 10px;
    margin-bottom: 14px;
}

.details-view {
    .ant-col {
        margin-bottom: 20px;
    }

    label {
        display: block;
        font-weight: 600;
    }
}

.bg-primary-gradient {
    background: #073f5c;
    color: #fff;

    .ant-card-head,
    .po-summary-heading {
        color: #fff;
    }

    .po-information {
        span {
            font-weight: 600;
        }
    }
}

.financials {
    .ant-card {
        border-radius: 7px;
    }

    .dashboard-card-text {
        font-size: 2.5rem;
    }
}

.full-height {
    height: 100%;
}

.more-icon {
    font-size: 20px;
    transform: rotate(90deg);
    cursor: pointer;
}

.card-grid-sm {
    width: 33.333%;
    text-align: center;
    padding: 12px;
    background: #073f5c;

    h4 {
        color: #fff;
        margin-bottom: 0.2em;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.checkbox-group-block-items {
    .ant-checkbox-group-item {
        width: 100%;
        margin-bottom: 5px;
    }
}

.ant-radio-button-wrapper-checked:not(
        [class*=' ant-radio-button-wrapper-disabled']
    ).ant-radio-button-wrapper:first-child {
    display: none;
}

.ant-picker-calendar-header .ant-picker-calendar-mode-switch {
    display: none;
}

.profile-settings-tabs {
    margin: auto;
    margin-top: 30px;
    max-width: 1000px;
}

.no-permissions {
    font-size: 24px;
    margin: 20px;
    height: 100%;
    width: 100%;
    text-align: center;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.ant-table-content {
    .active-row {
        background-color: #e7feff;
    }
}

.custom-tag {
    border-radius: 6px;
    font-size: 14px;
    width: 40px;
    text-align: center;
}

.custom-row {
    display: flex;
    margin-left: -20px;
    margin-right: -20px;

    .col {
        width: 20%;
        padding-left: 20px;
        padding-right: 20px;
        border-right: 1px solid #dbd9d9;

        &:first-child {
            .col-title {
                background-color: #acb1d6;
            }
        }

        &:nth-child(2) {
            .col-title {
                background-color: #ffd0d0;
            }
        }

        &:nth-child(3) {
            .col-title {
                background-color: #c1ece4;
            }
        }

        &:nth-child(4) {
            .col-title {
                background-color: #f5e9cf;
            }
        }

        &:last-child {
            border-right-width: 0;

            .col-title {
                background-color: #ecf2ff;
            }
        }

        .col-title {
            font-size: 0.9rem;
            background-color: #f1f1f1;
            text-align: center;
            padding: 6px 0;
            border-radius: 5px;
            margin-bottom: 20px;
        }
    }

    &.no-bordered {
        .col {
            border-right-width: 0;
        }
    }
}

.grey-title {
    .ant-card-head {
        background-color: #f9f9f9;
    }

    .ant-card-head-title {
        padding: 11px 0;
    }
}

.manual-schedule-table {
    .ant-table-thead {
        tr {
            &:first-child {
                th {
                    // Order
                    &:nth-child(5) {
                        color: #000 !important;
                        background-color: #acb1d6;
                    }
                    // Approval
                    &:nth-child(6) {
                        color: #000 !important;
                        background-color: #ffd0d0;
                    }
                    // Release
                    &:nth-child(7) {
                        color: #000 !important;
                        background-color: #c1ece4;
                    }
                    // Testing
                    &:nth-child(8) {
                        color: #000 !important;
                        background-color: #f5e9cf;
                    }
                    // Ship
                    &:nth-child(9) {
                        color: #000 !important;
                        background-color: #ecf2ff;
                    }
                }
            }
            &:nth-child(2) {
                th {
                    // Order
                    &:first-child {
                        color: #000 !important;
                        background-color: #acb1d6;
                    }
                    &:nth-child(2) {
                        color: #000 !important;
                        background-color: #acb1d6;
                    }
                    &:nth-child(3) {
                        color: #000 !important;
                        background-color: #acb1d6;
                    }
                    // Approval
                    &:nth-child(4) {
                        color: #000 !important;
                        background-color: #ffd0d0;
                    }
                    &:nth-child(5) {
                        color: #000 !important;
                        background-color: #ffd0d0;
                    }
                    &:nth-child(6) {
                        color: #000 !important;
                        background-color: #ffd0d0;
                    }
                    &:nth-child(7) {
                        color: #000 !important;
                        background-color: #ffd0d0;
                    }
                    // Release
                    &:nth-child(8) {
                        color: #000 !important;
                        background-color: #c1ece4;
                    }
                    &:nth-child(9) {
                        color: #000 !important;
                        background-color: #c1ece4;
                    }
                    // Testing
                    &:nth-child(10) {
                        color: #000 !important;
                        background-color: #f5e9cf;
                    }
                    &:nth-child(11) {
                        color: #000 !important;
                        background-color: #f5e9cf;
                    }
                    // Ship
                    &:nth-child(12) {
                        color: #000 !important;
                        background-color: #ecf2ff;
                    }
                    &:nth-child(13) {
                        color: #000 !important;
                        background-color: #ecf2ff;
                    }
                    &:nth-child(14) {
                        color: #000 !important;
                        background-color: #ecf2ff;
                    }
                }
            }
        }
    }
}

.text-black {
    color: #000 !important;
}

.bg-light-purple {
    background-color: #ecf2ff !important;
}

.bg-grey {
    background-color: #f0f0f0 !important;
}

.bg-light-green {
    background-color: #c1ece4 !important;
}

.bg-light-yellow {
    background-color: #f5e9cf !important;
}

.text-capitalize {
    text-transform: capitalize;
}

.warning-icon {
    font-size: 35px;
    color: #faad14;
}

.fontSize-15 {
    font-size: 15px;
}

.fontSize-13 {
    font-size: 13px;
}

.document-tabs {
    .ant-tabs-nav-wrap .ant-tabs-tab {
        text-align: left;
        justify-content: left;
    }
}

.bg-white {
    background-color: #fff;
}

/* Scroll style global */
::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
/* Scroll style global */

.text-align-center {
    text-align: center;
}

.color-light-grey {
    color: $alectify-light-grey-empty-color;
}

.align-items-center {
    align-items: center;
}

// layouts

.alectify-header {
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
    border-bottom: 1px solid #d7d7d7;
    z-index: 9;

    .header-right {
        display: flex;
        align-items: center;
    }
}

.site-layout .site-layout-background {
    background: #fff;
}

.site-layout {
    height: 100vh;
}

.content-layout {
    overflow: auto;
    height: 100%;
}

.alectify-table-search-input {
    margin-bottom: 0;

    .ant-input-affix-wrapper {
        height: 35px;
        width: 266px;
        border: 1px solid var(--grey-strokes-outlines, #dae5f3);
        input {
            height: 27px !important;
        }
    }
}

.alectify-centered-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
