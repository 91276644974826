.welcome-message-text {
    font-weight: 700 !important;
}

.alectify-map-container {
    width: 100%;
    height: calc(100vh - 224px);

    .alectify-master-project-header-container {
        margin-top: 10px;
        margin-bottom: 0px;
    }
    .ant-typography {
        margin-top: 0 !important;
    }
    .text-blue-bold {
        font-size: 16px;
        font-weight: 500;
        line-height: 21px;
        color: #0954f1;
    }
}
