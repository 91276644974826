.empty-state-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    p {
        color: #84919a;
        font-size: 16px;
        font-weight: 500;
    }
}
