@import '../../../app/assets/styles/variables.scss';

.sub-project-card {
    height: 7.9rem;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 5px;
    background: #fff;
    box-shadow: 0px 0px 13px 3px rgba(0, 0, 0, 0.16);
    &:hover {
        box-shadow: 0px 0px 13px 5px rgba(0, 0, 0, 0.16);
    }
    .draft-container {
        background-color: #faddd4;
        border-radius: 10px;
        font-size: 12px;
        color: #e33507;
        border: 1px solid #e33507;
        width: fit-content;
        padding-left: 10px;
        padding-right: 10px;
    }

    .header {
        margin-bottom: 0.2em;
        display: flex;
        align-items: center;
        h1 {
            font-size: 0.9rem;
            margin: 0;
        }
        .draft-edit-icons {
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
    }

    .body {
        margin-bottom: 5px;

        div.ant-typography {
            color: $text-color-light-grey;
            font-size: 14px;
            margin-bottom: 14px;
        }
    }

    .footer {
        padding-top: 10px;
        border-top: 1px solid #e8f1fd;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 5px;
        .ant-tag {
            display: inline-flex;
            padding: 3px 8px;
            justify-content: center;
            align-items: center;
            gap: 3px;
            border-radius: 10000px;

            & > span {
                vertical-align: middle;
                font-size: 14px;
                margin-left: 5px;
            }

            img {
                vertical-align: middle;
            }
            svg {
                height: 18px !important;
            }
        }
    }
    .alectify-sub-project-card-description {
        .ant-typography {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .ant-dropdown-menu-submenu-title {
        font-size: 13px !important;
    }
}

.bidding-icons-container {
    padding: 5px;
    cursor: auto;
}
.bid-won-container {
    display: flex;
    align-items: center;
    text-align: center;
    margin-top: 7px;
    width: fit-content;
    border-radius: 10px;
    font-size: 12px;
    padding-left: 10px;
    padding-right: 10px;
    color: #389e0d;
    background: #f6ffed;
    border-color: #b7eb8f;
    border: 1px solid #389e0d;
}
