$primary-color: #16aaff;
$green-color: #1dbf73;
$dark-primary-color: #0098ef;
$dark-blue-color: #233044;
$light-bg-color-primary: #1890ff21;
$dark-text-color-primary: #253232;
$text-grey: #555;

$alectify-black: #000000;
$alectify-white: #ffff;
$alectify-red: #ff2323;
$alectify-green: #005f20;
$alectify-blue: #070bce;
$alectify-lightgray: #f5f7fa;
$alectify-main-blue: #545454;
$alectify-secondary-black: #252525;
$alectify-darkgray: #454545;
$text-color-light-grey: #84919a;
$alectify-light-blue-color: #e8f1fd;
$alectify-main-color: #141b34;
$alectify-primary-blue: #0954f1;
$alectify-gray-color: #eef0f2;
$alectify-font-size-15: 15px;
$alectify-secondary-color: #545454;
$alectify-disabled-grey-color: #b9bbc7;
$alectify-dark-grey-editor-color: #8a8a8a;
$alectify-light-grey-empty-color: #bababa;
$alectify-waring-bg-color: #fff5f5;

$alectify-border-color: #dae5f3;

$alectify-regular: 'alectify-regular', Roboto, Helvetica Neue, sans-serif;
